// /* global google */

$(document).on('ready', function () {
  $('.burger-wrapper').click(function () {
    $(this).addClass('mask');
  });

  let changeImage = function () {
    let elemsForChangeBg = document.querySelectorAll('.full_width_image');
    let newElems = Array.from(elemsForChangeBg);

    let changeBg = newElems.map((item) => {
      let bg = item.getAttribute('data-bg');
      let bgMob = item.getAttribute('data-bg-2');
      item.classList.add('bg-cover');
      if (window.screen.width < 960) {
        item.style.backgroundImage = `url('${bgMob}')`;
      } else {
        item.style.backgroundImage = `url('${bg}')`;
      }
    });

    return changeBg;
  };

  changeImage();

  /*
   *  Attorney slider
   */

  $('.attorney-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    adaptiveHeight: true,
    arrows: false,
    dots: false,
    asNavFor: '.slider-small-photo',
  });
  $('.slider-small-photo').slick({
    slidesToShow: 6,
    infinite: true,
    focusOnSelect: true,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    asNavFor: '.attorney-slider',
    dots: false,
    arrow: true,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  });
  // ACF Google Map JS code

  /*
   *  This function will render a Google Map onto the selected jQuery element
   */

  // function render_map($el) {
  //   // var
  //   var $markers = $el.find('.marker');
  //   var styles = [
  //     {
  //       featureType: 'all',
  //       elementType: 'labels.text.fill',
  //       stylers: [
  //         {
  //           saturation: 36,
  //         },
  //         {
  //           color: '#333333',
  //         },
  //         {
  //           lightness: 40,
  //         },
  //       ],
  //     },
  //     {
  //       featureType: 'all',
  //       elementType: 'labels.text.stroke',
  //       stylers: [
  //         {
  //           visibility: 'on',
  //         },
  //         {
  //           color: '#ffffff',
  //         },
  //         {
  //           lightness: 16,
  //         },
  //       ],
  //     },
  //     {
  //       featureType: 'all',
  //       elementType: 'labels.icon',
  //       stylers: [
  //         {
  //           visibility: 'off',
  //         },
  //       ],
  //     },
  //     {
  //       featureType: 'administrative',
  //       elementType: 'geometry.fill',
  //       stylers: [
  //         {
  //           color: '#fefefe',
  //         },
  //         {
  //           lightness: 20,
  //         },
  //       ],
  //     },
  //     {
  //       featureType: 'administrative',
  //       elementType: 'geometry.stroke',
  //       stylers: [
  //         {
  //           color: '#fefefe',
  //         },
  //         {
  //           lightness: 17,
  //         },
  //         {
  //           weight: 1.2,
  //         },
  //       ],
  //     },
  //     {
  //       featureType: 'landscape',
  //       elementType: 'geometry',
  //       stylers: [
  //         {
  //           color: '#f5f5f5',
  //         },
  //         {
  //           lightness: 20,
  //         },
  //       ],
  //     },
  //     {
  //       featureType: 'poi',
  //       elementType: 'geometry',
  //       stylers: [
  //         {
  //           color: '#f5f5f5',
  //         },
  //         {
  //           lightness: 21,
  //         },
  //       ],
  //     },
  //     {
  //       featureType: 'poi.park',
  //       elementType: 'geometry',
  //       stylers: [
  //         {
  //           color: '#dedede',
  //         },
  //         {
  //           lightness: 21,
  //         },
  //       ],
  //     },
  //     {
  //       featureType: 'road.highway',
  //       elementType: 'geometry.fill',
  //       stylers: [
  //         {
  //           color: '#ffffff',
  //         },
  //         {
  //           lightness: 17,
  //         },
  //       ],
  //     },
  //     {
  //       featureType: 'road.highway',
  //       elementType: 'geometry.stroke',
  //       stylers: [
  //         {
  //           color: '#ffffff',
  //         },
  //         {
  //           lightness: 29,
  //         },
  //         {
  //           weight: 0.2,
  //         },
  //       ],
  //     },
  //     {
  //       featureType: 'road.arterial',
  //       elementType: 'geometry',
  //       stylers: [
  //         {
  //           color: '#ffffff',
  //         },
  //         {
  //           lightness: 18,
  //         },
  //       ],
  //     },
  //     {
  //       featureType: 'road.local',
  //       elementType: 'geometry',
  //       stylers: [
  //         {
  //           color: '#ffffff',
  //         },
  //         {
  //           lightness: 16,
  //         },
  //       ],
  //     },
  //     {
  //       featureType: 'transit',
  //       elementType: 'geometry',
  //       stylers: [
  //         {
  //           color: '#f2f2f2',
  //         },
  //         {
  //           lightness: 19,
  //         },
  //       ],
  //     },
  //     {
  //       featureType: 'water',
  //       elementType: 'geometry',
  //       stylers: [
  //         {
  //           color: '#e9e9e9',
  //         },
  //         {
  //           lightness: 17,
  //         },
  //       ],
  //     },
  //   ]; // Uncomment for map styling
  //
  //   // vars
  //   var args = {
  //     zoom: $(window).width() < 960 ? 7 : 8,
  //     center: new google.maps.LatLng(0, 0),
  //     mapTypeId: google.maps.MapTypeId.ROADMAP,
  //     scrollwheel: false,
  //     styles: styles, // Uncomment for map styling
  //   };
  //
  //   // create map
  //   var map = new google.maps.Map($el[0], args);
  //
  //   // add a markers reference
  //   map.markers = [];
  //
  //   // add markers
  //   $markers.each(function () {
  //     add_marker($(this), map);
  //   });
  //
  //   // center map
  //   center_map(map);
  // }

  /*
   *  This function will add a marker to the selected Google Map
   */

  // var infowindow;
  // function add_marker($marker, map) {
  //   // var
  //   var latlng = new google.maps.LatLng(
  //     $marker.attr('data-lat'),
  //     $marker.attr('data-lng')
  //   );
  //
  //   // create marker
  //   var marker = new google.maps.Marker({
  //     position: latlng,
  //     map: map,
  //     icon: $marker.data('marker-icon'), //uncomment if you use custom marker
  //   });
  //
  //   // add to array
  //   map.markers.push(marker);
  //
  //   // if marker contains HTML, add it to an infoWindow
  //   if ($.trim($marker.html())) {
  //     // create info window
  //     infowindow = new google.maps.InfoWindow();
  //
  //     // show info window when marker is clicked
  //     google.maps.event.addListener(marker, 'click', function () {
  //       // Close previously opened infowindow, fill with new content and open it
  //       infowindow.close();
  //       infowindow.setContent($marker.html());
  //       infowindow.open(map, marker);
  //     });
  //   }
  // }

  /*
   *  This function will center the map, showing all markers attached to this map
   */

  // function center_map(map) {
  //   // vars
  //   var bounds = new google.maps.LatLngBounds();
  //
  //   // loop through all markers and create bounds
  //   $.each(map.markers, function (i, marker) {
  //     var latlng = new google.maps.LatLng(
  //       marker.position.lat(),
  //       marker.position.lng()
  //     );
  //     bounds.extend(latlng);
  //   });
  //
  //   // only 1 marker?
  //   if (map.markers.length == 1) {
  //     // set center of map
  //     map.setCenter(bounds.getCenter());
  //   } else {
  //     // fit to bounds
  //     // map.fitBounds(bounds);
  //     map.setCenter(bounds.getCenter());
  //   }
  // }

  /*
   *  This function will render each map when the document is ready (page has loaded)
   */

  // $('.acf-map').each(function () {
  //   render_map($(this));
  // });

  if ($(window).width() <= '960') {
    $('.menu-burger').css('display', 'block');
    $('.hide-for-desc').show();
  } else {
    $('.hide-for-desc').hide();
  }

  /* Read more text */

  $('.section__block-text__readmore').on('click', function () {
    let top = $(this).closest('.section__block-text').offset().top;
    $(this).toggleClass('section__block-text__readmore--up');
    $('.block-text--second').toggle('slow');

    if ($(this).hasClass('section__block-text__readmore--up')) {
      $(this).text('read less');
    } else {
      $(this).text('read more');
      $('html, body').animate({ scrollTop: top }, 500);
    }
  });

  /* Bio arrow up */
  if ($(window).width() <= '960') {
    $('.bio-title').on('click', function () {
      $(this).toggleClass('bio-title--up');
      $(this)
        .closest('.attorney-bio__inner')
        .find('.bio-wrapper-content')
        .toggle('slow');
    });
  }

  /* Footer menu */
  $('.footer-menu .menu-item-has-children').on('click', function () {
    $(this).toggleClass('menu-item-has-children--arrow-up');
    $(this).find('.submenu').toggle('slow');
  });

  /* Footer locations */
  $('.locations-dropdown-toggle').on('click', function () {
    $(this).toggleClass('locations-dropdown-toggle--up');
    $(this)
      .closest('.footer-mobile-menu__locations')
      .find('.footer-sites-list')
      .toggle('slow');
  });

  $('.ginput_container_select').on('click', function () {
    $(this).toggleClass('ginput_container_select--top');
  });

  $('.header-contact__link').on('click', function () {
    $(this).toggleClass('arrow-up');
    $(this)
      .closest('.header-contact__locations')
      .find('.header-contact__locations-subsites')
      .toggle('slow');
  });
  const header = document.querySelector('.header-contact');
  $("a[href='#contact-form']").click(function () {
    $('html, body').animate(
      { scrollTop: $('#contact-form').offset().top - header.clientHeight },
      'slow'
    );
  });
  /*
   * Search form
   */
  // $('.header-bottom__search').hover(function () {
  //   if ($('.search__submit').val()) {
  //     $('.search__submit').toggle();
  //   }
  // });

  /* Header contact */
  setTimeout(function () {
    let headerContactHeight = $('.header-contact').outerHeight(false);
    $('.push-bottom').css('height', headerContactHeight);
  }, 500);

  /* Responsive header */
  $('.burger-wrapper').on('click', function () {
    $(this).toggleClass('burger-wrapper--close');
    if ($(this).hasClass('burger-wrapper--close')) {
      $('.header').show('slow');
    } else {
      $('.header').hide('slow');
    }
  });

  $('.menu-burger').on('click', function () {
    $('body').toggleClass('mask');
  });

  const anchor = document.querySelector('a[href="#contact-form"]');

  anchor.addEventListener('click', function (e) {
    e.preventDefault();
    document.querySelector('#contact-form').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  });

  jQuery(function ($) {
    $(document).mouseup(function (e) {
      let searchDiv = $('.header-bottom__search');
      if (!searchDiv.is(e.target) && searchDiv.has(e.target).length === 0) {
        // $('.search__submit').show().attr('disabled', 'disabled');
        $('.search__input').val('');
      }
    });
  });
  $('.attorney-hero--right a').on('mousedown', function (e) {
    e.preventDefault();
    var href = $(this).attr('href').replace('#', '');
    $('html,body').animate(
      {
        scrollTop:
          $('#' + href).offset().top - $('header-contact').outerHeight(),
      },
      1000
    );
  });
});
